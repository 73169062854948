<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
                <b-row>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Website Title" rules="required">
                            <b-form-group label="Website Title">
                                <b-form-input trim placeholder="Website Title" v-model="dataInfo.website_title" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Head Codes">
                            <b-form-group label="Head Codes">
                                <b-form-textarea trim placeholder="Head Codes" v-model="dataInfo.head_codes" :state="getValidationState(validationContext)" rows="4"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Body Codes">
                            <b-form-group label="Body Codes">
                                <b-form-textarea trim placeholder="Body Codes" v-model="dataInfo.body_codes" :state="getValidationState(validationContext)" rows="4"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="6" md="6" lg="6" class="mb-3">
                        <validation-provider #default="validationContext" name="Head Content">
                            <b-form-group label="Head Content">
                                <quill-editor v-model="dataInfo.head_content" :options="{theme: 'snow', toolbar:'full'}"/>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="6" md="6" lg="6" class="mb-3">
                        <validation-provider #default="validationContext" name="Footer Content">
                            <b-form-group label="Footer Content">
                                <quill-editor v-model="dataInfo.footer_content" :options="{theme: 'snow', toolbar:'full'}"/>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                </b-row>

                <b-row class="mt-3">
                    <b-col cols="4" md="4" lg="4">
                        <validation-provider #default="validationContext" name="Facebook Url">
                            <b-form-group label="Facebook Url">
                                <b-form-input trim placeholder="Facebook Url" v-model="dataInfo.facebook_url" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="4" md="4" lg="4">
                        <validation-provider #default="validationContext" name="Twitter Url">
                            <b-form-group label="Twitter Url">
                                <b-form-input trim placeholder="Twitter Url" v-model="dataInfo.twitter_url" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="4" md="4" lg="4">
                        <validation-provider #default="validationContext" name="Instagram Url">
                            <b-form-group label="Instagram Url">
                                <b-form-input trim placeholder="Instagram Url" v-model="dataInfo.instagram_url" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>

                <action-buttons :back-route="'home'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BAvatar,
    BTabs,
    BTab,
    BFormTextarea,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BLink,
    BImg,
    BCardText,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/settings/store"
import categoryModule from "@/views/cms/category/store"
import contentModule from "@/views/cms/content/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {quillEditor} from 'vue-quill-editor'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {getApiFile, statusOptions} from "@core/utils/filter"
import {toastMessage} from "@core/utils/utils"

export default {
    components: {
        BCardText,
        BImg,
        BLink,
        BMediaAside,
        BFormFile,
        BMediaBody,
        BMedia,
        BFormTextarea,
        BTab,
        BTabs,
        BAvatar,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,

        vSelect,
        ActionButtons,
        Overlay,

        ValidationProvider,
        ValidationObserver,

        quillEditor,
    },
    data() {
        return {
            required,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_CATEGORY_MODULE_NAME = 'store-category'
        const STORE_CONTENT_MODULE_NAME = 'store-content'

        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
            store.registerModule(STORE_CONTENT_MODULE_NAME, contentModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
                store.unregisterModule(STORE_CONTENT_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)

        const dataInfo = ref({
            website_title: '',
            head_codes: '',
            body_codes: '',
            head_content: '',
            footer_content: '',
            yearly_fee_amount: 0,
            monthly_fee_amount: 0,
            north_jersey_email: '',
            south_jersey_email: '',
            facebook_url: '',
            twitter_url: '',
            instagram_url: '',
            training_events_category_id: null,
            crafts_we_offer_category_id: null,
            projects_category_id: null,
            testimonials_category_id: null,
            home_content_1: null,
            home_content_2: null,
        })

        const onSubmit = () => {
            busy.value = true
            store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                toastMessage(toast, 'success', response.data.message)
                router.push({name: 'cms-category-list'})
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.error_message)
            }).finally(message => {
                busy.value = false
            })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()


        busy.value = true
        store.dispatch('store/fetchItem').then(response => {
            let resData = response.data.data
            if (resData !== null) {
                dataInfo.value = resData
            }
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.error_message)
            if (error.response.status === 403) {
                router.push({name: 'home'})
            }
        }).finally(message => {
            busy.value = false
        })

        const categoryOptions = ref([])
        busy.value = true
        store.dispatch('store-category/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.status === 1 && value.id !== dataInfo.value.id) {
                    categoryOptions.value.push({label: value.title, value: value.id})
                }
            })
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'home'})
            }
        }).finally(message => {
            busy.value = false
        })

        const contentOptions = ref([])
        busy.value = true
        store.dispatch('store-content/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.status === 1 && value.id !== dataInfo.value.id) {
                    contentOptions.value.push({label: value.title, value: value.id})
                }
            })
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'home'})
            }
        }).finally(message => {
            busy.value = false
        })

        return {
            busy,
            dataInfo,
            refFormObserver,
            categoryOptions,
            contentOptions,

            statusOptions,

            onSubmit,
            getValidationState,
            resetForm,
            getApiFile,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.quill-editor {
  height: 200px;
}

</style>
